import * as React from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { Dialog } from "../Dialog";
import { Mutation } from "../../generated/nest-graphql";
import { useMutation } from "@apollo/client";
import { SEND_ADD_CONTACT_CARD_SMS } from "../../graphql/mutations/sendAddContactCardSms";
import { useDispatch } from "../../contexts/snackbar-context";
import { showErrorAlert, showSuccessAlert } from "../../actions";
import * as Sentry from "@sentry/browser";

export const TextCustomerCCLinkDialog: React.FC<{
  contactId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  market?: string;
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose, contactId, firstName, lastName, phoneNumber, market }) => {
  const dispatch = useDispatch();
  const [sendAddCardSms, { loading }] = useMutation<Mutation>(SEND_ADD_CONTACT_CARD_SMS);
  const handleSms = async () => {
    try {
      await sendAddCardSms({
        variables: { contactId, market },
      });
      showSuccessAlert(dispatch, "CC link sent");
      onClose();
    } catch (e) {
      showErrorAlert(dispatch, "Failed Sending CC link");
      onClose();
      Sentry.captureException(e, {
        extra: { contactId },
      });
    }
  };
  return (
    <Dialog title={"Text customer link to add new card"} open={open} onClose={onClose}>
      <div className="mb-4">
        <Typography gutterBottom>
          By hitting confirm, an SMS link to add a new credit card will be texted to your customer, {firstName}{" "}
          {lastName} at the number {phoneNumber}
        </Typography>
      </div>
      <div className="mb-8">
        <Typography gutterBottom>This link will be valid for one hour</Typography>
      </div>
      <div className="flex justify-between mb-4">
        <Button onClick={onClose} size={"large"} color={"default"} variant={"outlined"}>
          Cancel
        </Button>
        <Button disabled={loading} onClick={handleSms} size={"large"} color={"primary"} variant={"contained"}>
          {loading ? <CircularProgress /> : "Send Link"}
        </Button>
      </div>
    </Dialog>
  );
};
